.box{
    border: solid black 1px;
    margin: 20px;
    width: 720px;
}
.list{
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.input{
    border: solid black 1px;
    width: 150px;
    margin-left: 10px;
}
.itemBox{
    border: solid black 1px;
    padding: 10px;

}
.title{
    font-size: 25px;
}
.btn{
    width: 200px;
    border-radius: 5px;
    font-size: 25px;
}
.btn:hover{
    background-color: burlywood;
}