.box {
  display: flex;
}
.shopBox {
  margin-left: 20px;
  border: solid black 1px;
  padding: 20px;
}
.list {
  padding: 0;
}
